import React from "react";
import "../Footer/footer.css";

const Footer = () => {
  return (
    <div className="Footer">
      <div className="Footer_window">
        <a>COPYRIGHT 2023 ALISHER&RASHAD</a>
      </div>
    </div>
  );
};
export default Footer;
