import React from "react";
import "../Popup/popup.css";
import { useState } from "react";
import Close from "../assets/close.svg";
const Popup = ({ active, setActive }) => {
  return (
    <div className={active ? "popup active" : "popup"}>
      <div className="popup_window">
        <button
          onClick={() => {
            setActive(false);
          }}
        ></button>

        <div className="popup_window-top">
          <a>ЦЕНЫ</a>
        </div>

        <div className="popup_window-prices">
          <a>
            1.Установка windows и драйверов <span>1500р</span>{" "}
          </a>
          <a>
            2.Разборка ноутбука и чистка системы охлаждения,замена термопасты -
            <span>2000р</span> (Обычный ноутбук)
          </a>
          <a>
            3.Разборка ноутбука и чистка системы охлаждения, замена термопасты
            (игровой ноутбук)-<span>3000р</span>
          </a>
          <a>
            4.Замена матрицы ноутбука <span>1500р</span>{" "}
          </a>
          <a>
            5.Замена матрицы игрового ноутбука <span>2500р</span>
          </a>
          <a>
            6.Чистка системы охлаждения компьютера замена термопасты{" "}
            <span>1500р</span>
            (обычный компьютер).
          </a>
          <a>
            7.Чистка системы охлаждения замена термопасты (игровой компьютер){" "}
            <span>2500р</span>
          </a>
          <a>
            8.Обслуживание всего компьютера от <span>2500-4000р</span>
          </a>
          <a>
            9.Сборка компьютера до 100000 <span> - 4000р </span>,
            <br /> до <span>150000- 4500р</span>, до 200000- <span>6000р</span>,
            до 250000- <span>8000р</span>, до 300000- <span>10000р</span> <br />
            В стоимость входит кабель менеджмент и установка программного
            обеспечения.
          </a>
          <a>
            10.Прошивка Биос -<span>1500р</span>
          </a>
          <a>
            11.Апгрейд компьютера по согласованию с клиентом в зависимости от
            сложности сборки .
          </a>
          <a>
            12.Настройка роутера -<span>1000р</span>
          </a>
          <a>
            13.Чистка системных файлов windows удаление вирусов и оптимизация
            системы <span>1500р</span>.
          </a>
          <a>
            14.Установка системы жидкостного охлаждения <span>2000р</span>
          </a>
          <a>
            15.Удаление смс баннеров <span>1500р</span>
          </a>
          <a>16.Диагностика бесплатная</a>
          <a>
            17.Выезд по Москве бесплатно за пределы МКАД
            <span>500р</span>.
          </a>
        </div>
      </div>
    </div>
  );
};
export default Popup;
